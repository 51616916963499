import { ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mapGetters } from "vuex";
import { mixins } from "@/plugins/mixins";
import bus from "@/plugins/bus";
export default {
  name: "roleAdd",
  mixins: [mixins],
  computed: {
    ...mapGetters({
      isAdmin: 'perm/isAdmin'
    })
  },
  data() {
    return {
      edit: false,
      orgList: [],
      formModel: {
        roleId: null,
        roleName: null,
        remark: null,
        orgId: null
      },
      formRules: {
        roleName: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        }, {
          max: 32,
          message: '角色名称长度不能超过32',
          trigger: 'blur'
        }],
        remark: [{
          max: 64,
          message: '备注长度不能超过64',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.uac.role.update(this.formModel).then(callBack);
          } else {
            this.$api.uac.role.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.roleId) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.uac.role.detail(this.$route.query.roleId).then(res => {
        if (res.code === 200) {
          this.formModel = Object.assign(this.formModel, res.data);
        } else if (res.code === 101202) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
    if (this.isAdmin) {
      this.$api.uac.org.list().then(res => {
        if (res.code === 200) {
          this.orgList = res.data;
        }
      });
    }
  }
};